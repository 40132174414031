$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    /**
     * ACCORDION CALL
     */
    $('.accHandler').accordion({openFirst:true});

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC.
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    if ( breakpoint['medium'] ) {
        // Toggle the "hovering" class on elements
        $('.tapHover').on('touchend', tapHover);
    }

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');

    if(touchEnabled && !breakpoint['medium']) {
        $('body').on('swipeleft swiperight', function(e) {
            naviToggled.onchange(e);
        });
    }
});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/

/**
 * prevent body scrolling on toggled navi
 */
var naviToggled = document.getElementById('navi-toggled');

naviToggled.onchange=function(a){bool=null;switch(a.type){case'swipeleft':a.swipestart.coords[0]>$(window).width()-4*baseSize&&(bool=!0);break;case'swiperight':bool=!1;break;default:bool=this.checked}this.checked=bool,parent.style.overflow=bool?'hidden':'auto'};

var touchEnabled = (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));

function offset() {
    return window.pageYOffset || document.documentElement.scrollTop || 0
}

var menu = document.querySelector('.top');
var menuPosition = menu.getBoundingClientRect();
var placeholder = document.createElement('div');
placeholder.style.width = menuPosition.width + 'px';
placeholder.style.height = menuPosition.height + 'px';

var isAdded = false;

window.addEventListener('scroll', function() {
    if (offset() >= menuPosition.top && !isAdded) {
        menu.classList.add('sticky');
        menu.parentNode.insertBefore(placeholder, menu);
        isAdded = true;
    } else if (offset() <= menuPosition.top && isAdded) {
        menu.classList.remove('sticky');
        menu.parentNode.removeChild(placeholder);
        isAdded = false;
    }
});

gM = {
    iframe: document.createElement('iframe'),
    scrolled: function() {
        return window.pageYOffset || document.documentElement.scrollTop || 0
    },
    createIframe: function(callback, offset) {
        if( gM.offset <= (gM.scrolled() + windowHeight) ) {

            gM.element.parentNode.replaceChild(gM.iframe, gM.element);
            window.removeEventListener('scroll', listener, false);
            callback();
        }
    },
    init: function(_element, offset, callback) {
        callback = callback || function(){ console.log('Google Maps initialized') };
        gM.element = document.querySelector(_element);
        gM.iframe.src = gM.element.getAttribute('data-src');
        gM.offset = gM.element.getBoundingClientRect().top + gM.scrolled() - offset;

        listener = function() {
            gM.createIframe(callback)
        }

        if(gM.element) window.addEventListener('scroll', listener, false);

        gM.createIframe(callback, offset);
    }
};

gM.init('.maps', 400);